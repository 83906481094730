footer {
  display: block;
  position: fixed;
  width: 100%;
  min-width: 1024px;
  height: 100px;
  letter-spacing: normal;
  z-index: 500;
  bottom: 0px;

  @include media-xs {
    min-width: auto;
    height: 50px;
  }

  & > * {
    display: block;
    position: absolute;
    width: 100%;
  }

  .copyright {
    bottom: 10px;
    text-align: center;
    font-size: 10pt;
    padding: 5px 0px;
    z-index: 120;
    color: #fff;
  }
}