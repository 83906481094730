select,
input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='time'],
input[type='mail'],
textarea {
  width: 100%;
  color: #fff;
  border: 1px solid rgba(255,255,255,0.5);
  padding: 5px;
  background: none;
  -moz-transition: border ease-in 0.2s,background ease-in 0.2s;
  -o-transition: border ease-in 0.2s,background ease-in 0.2s;
  -webkit-transition: border ease-in 0.2s,background ease-in 0.2s;
  transition: border ease-in 0.2s,background ease-in 0.2s;
}

textarea {
  resize: none;
  min-height: 100px;
}

._style_button {
  &_normal {
    border-radius: 10px;
    background: none;
    background-color: rgba(255,255,255,0.2);
    border: 1px solid rgba(255,255,255,0.5);
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    padding: 5px;
    width: 50%;
    -moz-transition: background-color ease-in 0.2s, border-color ease-in 0.2s;
    -o-transition: background-color ease-in 0.2s, border-color ease-in 0.2s;
    -webkit-transition: background-color ease-in 0.2s, border-color ease-in 0.2s;
    -ms-transition: background-color ease-in 0.2s, border-color ease-in 0.2s;
    transition: background-color ease-in 0.2s, border-color ease-in 0.2s;

    @include media-xs {
      padding: 8px;
    }
  }

  &_invert {
    background-color: rgba(65,86,82,0.1);
    color: #415652;
    border: none;
  }
}

._class_button {
  display: inline-block;
  cursor: pointer;

  &:disabled {
    color: #999 !important;
  }
}

._style_textarea_preview {
  height: 70px;
  max-width: 100%;
  overflow: auto;
}