// _base/common
* {
  text-size-adjust: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-default-family;
  line-height: $line-height-base;
  font-size: $font-default-size;
  font-weight: $font-default-weight;
}

:root {
  --main-text-color: $base-text-color;
  --main-bg-color: $base-background-color;
}

.only {
  &--pc {
    display: initial;

    @include media-xs {
      display: none !important;
    }
  }
}