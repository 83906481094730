// _variables/general
//    Typography
$font-default-family: "Rounded Mplus 1c", "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", Meiryo, sans-serif;
$font-default-size: 62.5%;
$font-default-weight: 300;
$font-default-style: normal;
$line-height-base: 1.5;

// Breakpoints
$breakpoint-small: 767px;
$breakpoint-medium: 768px;
$breakpoint-large: 1023px;
$breakpoint-extralarge: 1024px;