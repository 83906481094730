#_ID_block_navigation {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  min-width: 100px;
  z-index: 600;
  text-align: left;

  @include media-xs {
    top: 5px;
    right: 5px;
  }

  & > .item {
    display: block;
    letter-spacing: normal;
    text-decoration: none;
    color: #fff;
    font-size: 12pt;
    margin-bottom: 5px;
    cursor: pointer;

    &::before {
      display: inline-block;
      content: "";
      width: 6px;
      height: 20px;
      border: 1px solid #fff;
      border-radius: 2px;
      margin-right: 5px;
      vertical-align: text-top;
    }
  }

  & > .active {
    &::before {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}