body {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  letter-spacing: -.40em;
  background: #fff;
  color: var(--main-text-color);
  z-index: 0;
  text-align: center;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, strong, b {
  font-weight: 400;
}

h1 {
  font-size: 18pt;
}

.text {
  &--center {
    text-align: center !important;
  }
}

#_ID_block_container {
  display: block;
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0px;
  text-align: left;
  overflow-y: hidden;
  z-index: 100;

  @include media-xs {
    overflow-x: hidden;
  }
}

#_ID_block_pagewrapper {
  display: block;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-height: 100%;
  padding-top: 0px;
  z-index: 150;

  ._class_block_document_full {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
} 

#_ID_block_background {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 110;
  background-color: rgb(237, 115, 42);
  width: 100%;
  height: 100%;
  -moz-transition: background-color ease-in 0.2s;
  -o-transition: background-color ease-in 0.2s;
  -webkit-transition: background-color ease-in 0.2s;
  -ms-transition: background-color ease-in 0.2s;
  transition: background-color ease-in 0.2s;
}

#_ID_block_mailsend_ok,
#_ID_block_mailsend_ng {
  ._style_block_document {
    .body {
      & > p {
        font-size: 1.4em;
        text-align: center;
      }
    }
  }
}

._class_block_information {
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -85%;
  z-index: 400;
  width: 70%;
  max-width: 1000px;
  min-width: 800px;
  height: 85%;
  background: #fff;
  margin: 0px auto;

  @include media-xs {
    width: 100%;
    max-width: none;
    min-width: auto;
  }

  ._style_block_document {
    margin-top: 0px;
    width: 100%;
    color: #415652;
  }
}

._style_block {
  &_document {
    display: block;
    position: absolute;
    width: 70%;
    max-width: 1000px;
    min-width: 800px;
    height: 70%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    letter-spacing: normal;
    padding: 10px;
    z-index: 90;
    color: #ffffff;

    @include media-xs {
      width: 100%;
      max-width: 100%;
      min-width: auto;
    }

    .title {
      display: block;
      text-align: right;
      position: relative;
      font-size: 4em;
      margin-top: 0;
      margin-bottom: 15px;

      @include media-xs {
        text-align: left;
        font-size: 3em;
      }
    }

    .description {
      text-align: center;
      font-size: 1.3em;

      @include media-xs {
        text-align: left;
        font-size: inherit;
      }

      strong {
        font-size: 1.5em;
      }
    }

    .body-wrapper {
      text-align: center;
      margin-top: 30px;

      @include media-xs {
        margin-top:10px;
      }

      .body {
        font-size: 1.4em;
        text-align: left;
        width: 100%;
      }

      ._style_block_form {
        width: 80%;

        @include media-xs {
          width: 100%;
        }
      }
    }
  }

  &_form {
    display: table;
    font-size: 1.3em;
    margin: auto;

    @include media-xs {
      display: block;
      font-size: inherit;
    }

    & > li {
      display: table-row;
      text-align: left;

      @include media-xs {
        display: block;
      }

      & > * {
        display: table-cell;
        padding-bottom: 5px;

        @include media-xs {
          display: block;
        }

        &:first-child {
          font-size: 1.2em;
          width: 25%;
          vertical-align: top;

          @include media-xs {
            width: 100%;
          }
        }

        .value {
          font-size: 1.3em;
          color: #006 !important;
          padding-left: 1em;
        }

        .message {
          font-size: 1.3em;
          color: #900 !important;
        }
      }


    }
  }

  &_buttonspace {
    margin-top: 10px;
    text-align: center;

    & > * {
      margin-bottom: 5px;
    }
  }

  &_border_topdown {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

._style_block_list {
  display: table;
  font-size: 1.3em;
  margin: auto;
  min-width: 750px;

  @include media-xs {
    width: 100%;
    min-width: auto;
  }

  & > li {
    display: table-row;
    text-align: left;

    @include media-xs {
      display: block;
    }

    & > * {
      display: table-cell;
      padding-bottom: 5px;

      @include media-xs {
        display: block;
      }

      &:first-child {
        width: 25%;
        vertical-align: top;
        font-weight: 400;
      }

      &:last-child {
        @include media-xs {
          font-size: 1.3em;
          padding-left: 10px;
        }
      }
    }
  }
}

._style_document_list1 {
  font-size: 1.2rem;
  text-align: left;
  width: 100%;
  list-style: none;
}

._lstyle_slider {
  width: 100%;
  height: 100%;

  .slides {
    width: 100%;
    height: 100%;

    & > * {
      position: relative;
      width: 100%;
      height: 100%;
    }

    ._slideimage {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
      width: 80%;
    }
  }

  &::after {
    display: block;
    position: absolute;
    background-image: url(/img/common/bg_pattern.png);
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 101;
    top: 0px;
    left: 0px;
  }
}

._pclass_sendmail_address {
  font-size: 2em !important;
  text-align: center;
  margin: 1em 0;
}

._style_text_alert {
  display: block !important;
  color: #900 !important;
}
