header {
  display: block;
  position: fixed;
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
  z-index: 500;
  text-align: left;

  @include media-xs {
    height: 100px;
  }

  .backgroundblock {
    display: block;
    position: absolute;
    width: 100%;
    height: 50px;
    z-index: 110;
  }

  .titleblock {
    display: block;
    position: absolute;
    width: 450px;
    height: 100%;
    z-index: 111;

    @include media-xs {
      width: 60%;
    }

    & > * {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }

    & > a {
      z-index: 120;
    }

    ._svg_titlelogo {
      max-width: 500px;
    }

    ._svg_titlelogo_face {
      display: block;
      width: 100px;
      top: 9px;
      left: 165px;
      z-index: 115;
      transform: scale(0, 0);
    }
  }
}